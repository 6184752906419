import React from 'react';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import ImageSlider from 'components/imageSlider';
import classNames from 'classnames';
import { Container } from './pageBlocks.css';
import Map from './map';
import Form from './form';
import Portal from 'bukazu-portal-react';

import 'bukazu-portal-react/build/index.css';

const PageBlocks = ({ blocks }) => {
  return (
    <Container>
      {blocks.map(function(block, index) {
        return <Block block={block} key={index} />;
      })}
    </Container>
  );
};

const Block = ({ block }) => {
  const bo = block.options;
  let val;
  let classes = '';
  Object.entries(bo.classes).forEach(element => {
    classes += '';
    classes += element[1];
  });

  classes = classNames(classes, bo.classes.width, bo.className);

  if (bo.type === 'text') {
    val = (
      <div
        style={bo.style}
        className={classes}
        dangerouslySetInnerHTML={{ __html: bo.content }}
      />
    );
  } else if (bo.type === 'image') {
    val = (
      <div style={bo.style} className={classes}>
        <img
          src={
            bo.content[0]
              ? 'https://cms.burobork.nl/images/' + bo.content[0].reference
              : null
          }
          alt={bo.content[0] ? bo.content[0].title : null}
        />
      </div>
    );
  } else if (bo.type === 'image_slider') {
    val = (
      <ImageSlider style={bo.style} className={classes} slides={bo.content} />
    );
  } else if (bo.type === 'iframe') {
    val = (
      <div style={bo.style} className={classes}>
        <iframe
          src={bo.content}
          width={bo.iframeWidth}
          height={bo.iframeHeight}
          title={bo.content}
        />
      </div>
    );
  } else if (bo.type === 'embed') {
    val = (
      <div style={bo.style} className={classes}>
        <embed
          src={'https://cms.burobork.nl/images/' + bo.content[0].reference}
          width={bo.embedWidth}
          height={bo.embedHeight}
          type={bo.embedType}
        />
      </div>
    );
  } else if (bo.type === 'map') {
    val = (
      <div style={bo.style} className={classes}>
        <Map
          latitude={Number(bo.location.lat)}
          longitude={Number(bo.location.lng)}
          isMarkerShown={true}
        />
      </div>
    );
  } else if (bo.type === 'bukazu_portal') {
    if (typeof window !== `undefined`) {
      val = (
        <div style={bo.style} className={classes}>
          {bo.portal_type === 'calendar' && (
            <div id="bukazu-app">
              <Portal
                portalCode={bo.portal_code}
                objectCode={bo.object_code}
                locale={bo.language}
              />
            </div>
          )}
          {bo.portal_type === 'search' && (
            <div id="bukazu-app">
              <Portal portalCode={bo.portal_code} locale={bo.language} />
            </div>
          )}
        </div>
      );
    } else {
      val = <div />;
    }
  } else if (bo.type === 'form') {
    val = (
      <div style={bo.style} className={classes}>
        <Form form={bo.content} />
      </div>
    );
  } else if (bo.type === 'imageGallery') {
    const images = bo.content.map(img => {
      if (img.reference) {
        return {
          original: 'https://cms.burobork.nl/images/' + img.reference,
          thumbnail: 'https://cms.burobork.nl/images/' + img.reference,
        };
      } else {
        return {
          original: 'https://' + img.preview,
          thumbnail: 'https://' + img.preview,
        };
      }
    });
    val = (
      <div style={{ ...bo.style, overflow: 'hidden' }} className={classes}>
        <ImageGallery
          items={images}
          showPlayButton={false} // thumbnailPosition="right"
          renderFullscreenButton={(onClick, isFullscreen) => (
            <button
              type="button"
              className={`image-gallery-fullscreen-button${
                isFullscreen ? ' active' : ''
              }`}
              onClick={onClick}
            />
          )}
        />
      </div>
    );
  } else {
    val = (
      <div
        style={bo.style}
        className={classes}
        dangerouslySetInnerHTML={{ __html: bo.content }}
      />
    );
  }
  return val;
};

PageBlocks.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default PageBlocks;
