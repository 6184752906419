import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  fill: ${props => (props.fill ? props.fill : props.theme.primaryColor)};
`;

const ChevronLeft = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 604 943"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,-2.27374e-13,0.0001)">
      <g transform="matrix(1,0,0,1,2024.37,-1979.31)">
        <path
          d="M-1788.75,2686.19L-2024.37,2450.56L-1788.75,2214.94C-1659.15,2085.35 -1552.56,1979.31 -1551.87,1979.31C-1550.23,1979.31 -1422.5,2107.06 -1422.5,2108.7C-1422.5,2109.38 -1499,2186.44 -1592.5,2279.94L-1762.5,2449.94L-1420.63,2791.81L-1485.62,2856.81C-1521.36,2892.56 -1551.18,2921.81 -1551.87,2921.81C-1552.56,2921.81 -1659.15,2815.78 -1788.75,2686.19Z"
          style={{ fillRule: 'nonzero' }}
        />
      </g>
    </g>
  </Svg>
);

ChevronLeft.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default ChevronLeft;
