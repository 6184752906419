import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import ChevronRight from 'icons/chevron_right.svg';
import ChevronLeft from 'icons/chevron_left.svg';
import { Image, Slide, Action } from './image.css';
import './swiper.css';

class ImageSlider extends React.Component {
  render() {
    const slides = this.props.slides;
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 5000,
      },
      renderPrevButton: () => (
        <Action className="swiper-button-prev">
          <ChevronLeft height="32px" />
        </Action>
      ),
      renderNextButton: () => (
        <Action className="swiper-button-next">
          <ChevronRight height="32px" />
        </Action>
      ),
    };
    return (
      <Swiper {...params}>
        {slides.map((slide, index) => (
          <Slide index={index} key={index}>
            <Image
              src={'https://cms.burobork.nl/images/' + slide.image[0].reference}
              alt={slide.title}
            />
          </Slide>
        ))}
      </Swiper>
    );
  }
}

ImageSlider.propTypes = {
  slides: PropTypes.array.isRequired,
};

export default ImageSlider;
