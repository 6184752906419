import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormContainer } from './form.css';
import axios from 'axios';

const Form = ({ form }) => {
  return (
    <Formik
      onSubmit={(values, actions) => {
        setTimeout(() => {
          axios.post(`https://cms.burobork.nl/recieve/form/${form.id}`, {
            form: {
              locale: 'nl',
              content: values,
            },
          });
          alert('Form has been sent!');
          actions.setSubmitting(false);
        }, 1000);
      }}
      render={({ values, errors, handleBlur, handleChange, handleSubmit }) => (
        <FormContainer>
          <form onSubmit={handleSubmit}>
            {form.options.fields.map(field => {
              if (field.type === 'select') {
                return (
                  <div key={field.model}>
                    <div htmlFor={field.model}>{field.label}</div>
                    <select
                      id={field.model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.model]}
                      name={field.model}
                    >
                      {field.values.map(opt => (
                        <option key={opt.option_value} value={opt.option_value}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              } else {
                return (
                  <div key={field.model}>
                    <label htmlFor={field.model}>
                      {field.label}
                      <input
                        id={field.model}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[field.model]}
                        name={field.model}
                        required={field.required}
                      />
                    </label>
                    {errors[field.name] && (
                      <div id="feedback">{errors[field.name]}</div>
                    )}
                  </div>
                );
              }
            })}
            <button type="submit">Verzenden</button>
          </form>
        </FormContainer>
      )}
    />
  );
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form;
